import Footer from '../../organisms/Footer';
import { shallowMount } from '@vue/test-utils';

const wrapper = shallowMount(Footer);

describe('StepButton', () => {
  it('has default text', () => {
    expect(wrapper.html()).toContain("PT Asuransi Jiwa Sequis Life berizin dan diawasi oleh Otoritas Jasa Keuangan");
  });
  
});