import Navigation from '../../organisms/Navigation';
import { shallowMount } from '@vue/test-utils';

const wrapper = shallowMount(Navigation);

describe('Navigation', () => {
  it("has default data", () => {
    expect(wrapper.vm.links).toEqual([{ path: 'https://www.sequis.co.id/id/tentang-sequis/life/profil-perusahaan', text: 'Tentang' },
      { path: 'https://www.sequis.co.id/id/contact-us', text: 'Hubungi kami' },
      { path: 'https://www.sequis.co.id/', text: 'Sequis.co.id' }
    ]);
    expect(wrapper.vm.sosmeds).toEqual([
      { imageUrl: require('../../../../assets/images/svg/vue/twitter.svg'), url: ' https://twitter.com/SequisOFFICIAL' },
      { imageUrl:  require('../../../../assets/images/svg/vue/facebook.svg'), url: 'https://www.facebook.com/sequislife/' },
      { imageUrl:  require('../../../../assets/images/svg/vue/instagram.svg'), url: ' https://www.instagram.com/sequisofficial/' },
      { imageUrl:  require('../../../../assets/images/svg/vue/youtube.svg'), url: 'https://www.youtube.com/ajsequislife' }
    ]);
  });
  it("trigger click open tab menu", () => {
    jest.spyOn(document, 'getElementById').mockReturnValueOnce({className: "topnav"});
    let result = wrapper.vm.openbar()
    expect(result.className).toBe('topnav responsive');

    jest.spyOn(document, 'getElementById').mockReturnValueOnce({className: ""});
    result = wrapper.vm.openbar()
    expect(result.className).toBe('topnav');
  });
});