<template>
  <div class="p-erecruitment-step2">
    <Navigation />
    <Container>
      <div class="row">
        <div class="col-sm-12 div-text-center step">
          <template v-for="(item, idx) in steps">
            <StepButton
              :key="idx"
              :text="item.text"
              :done="item.done"
              :position="idx"
              :active="item.active"
              @click="moveStep"
            />
            <hr
              v-if="idx != 4"
              :key="item.text"
              class="line"
            >
          </template>
        </div>
        <div class="col-sm-12 p-step div-text-center">
          <div class="title pt-5">
            What is your date of birth?
          </div>
          <div class="head">
            Masukkan Tanggal Lahir Anda
          </div>
          <div class="form-group form-content-center">
            <Datepicker
              :input-class="'form-control form-control-step-4'" 
              :placeholder="'Pilih tanggal'"
              :value="$store.state.date_of_birth"
              :open-date="openDate"
              :disabled="disabledDates"
              @input="changeDate"
            />
            <div
              v-if="error"
              class="text-error pt-2"
            >
              <img
                src="../../../../assets/images/svg/vue/ic-warning.svg"
                style="padding-right: 10px;"
              >
              {{ textError }}
            </div>
          </div>
        </div>
        <button
          class="button-fixed button-continue mt-3"
          @click="continueStep()"
        >
          LANJUTKAN
        </button>
      </div>
    </Container>
    <Footer />
  </div>
</template>

<script>

import Navigation from '../../organisms/Navigation';
import Container from '../../atoms/Container';
import StepButton from '../../atoms/StepButton';
import Footer from '../../organisms/Footer';
import Datepicker from 'vuejs-datepicker-rails';
import moment from 'moment';

export default {
  name: 'ErecruitmentStep2',
  components: {
    Navigation,
    Container,
    Footer,
    StepButton,
    Datepicker
  },
  props: {
    outside:  {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      day: "",
      month: "",
      year: "",
      error: false,
      textError: "",
      openDate: "",
      disabledDates: {
        customPredictor: function(date) {
          // highlights the date if it is a multiple of 4
          var day = new Date(Date.now()).getDate();
          var month = new Date(Date.now()).getMonth();
          var year = new Date(Date.now()).getFullYear();
          var sevenTeenth = new Date(year - 17, month, day + 1);
          if(date > sevenTeenth){
            return true
          }
        },
        includeDisabled: true // Highlight disabled dates
      },
      steps: [
        {
          text: 'STEP 1',
          active: false,
          done: true,
          component: 'ErecruitmentStep1',
        },
        {
          text: 'STEP 2',
          active: true,
          done: false,
          component: 'ErecruitmentStep2',
        },
        {
          text: 'STEP 3',
          active: false,
          done: false,
          component: 'ErecruitmentStep3',
        },
        {
          text: 'STEP 4',
          active: false,
          done: false,
          component: 'ErecruitmentStep4',
        },
        {
          text: 'STEP 5',
          active: false,
          done: false,
          component: 'ErecruitmentStep5',
        }],
    }
  },
  created() {
    this.openDate = moment().add(-17, 'years').format('YYYY-MM-DD');
    if(this.$store.state.full_name == "") {
      if (this.$route.query.utm_source || this.$route.query.utm_medium  || this.$route.query.utm_campaign ||  this.$route.query.ref) {
      this.$router.push({ name: 'ErecruitmentStep1' ,
        query: { 
          utm_source: this.$route.query.utm_source,
          utm_medium: this.$route.query.utm_medium,
          utm_campaign: this.$route.query.utm_campaign,
          ref: this.$route.query.ref}});
      } else {
        this.$router.push({ name: 'ErecruitmentStep1'});
      }
    }

    if (this.$route)  {
      if (this.$route.query) {
        this.$store.state.referral_key = this.$route.query.ref;
        this.$store.state.utm_source = this.$route.query.utm_source;
        this.$store.state.utm_medium = this.$route.query.utm_medium;
        this.$store.state.utm_campaign = this.$route.query.utm_campaign;
      }
    }
  },
  methods: {
    moveStep(index) {
      if (this.steps[index].done) {
        if (this.$route)  {
          if (this.$route.query) {
            this.$router.push({ name: this.steps[index].component, query: { 
            utm_source: this.$route.query.utm_source,
            utm_medium: this.$route.query.utm_medium,
            utm_campaign: this.$route.query.utm_campaign,
            ref: this.$route.query.ref}});
          } else {
            this.$router.push({ name: this.steps[index].component});
          }
        } 
      }
    },
    changeDate(e) {
      this.$store.state.date_of_birth =  moment(e).format('YYYY-MM-DD');
    },
    continueStep() {
      if (this.$store.state.date_of_birth == "" || this.$store.state.date_of_birth == null) {
        this.error = true;
        this.textError = "Field harus diisi";
      } else {
        if (this.$route.query.utm_source || this.$route.query.utm_medium  || this.$route.query.utm_campaign ||  this.$route.query.ref) {
        this.$router.push({ name: 'ErecruitmentStep3' ,
          query: { 
            utm_source: this.$route.query.utm_source,
            utm_medium: this.$route.query.utm_medium,
            utm_campaign: this.$route.query.utm_campaign,
            ref: this.$route.query.ref}});
        } else {
          this.$router.push({ name: 'ErecruitmentStep3'});
        }
      }
    },
  }
};
</script>