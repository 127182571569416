import Modal from '../../organisms/Modal';
import { mount } from '@vue/test-utils';

describe('Modal', () => {
  it("has default data", () => {
    const wrapper = mount(Modal, {
      propsData: {
      items: [
        {
          value: 'bapak',
          text: 'Bapak'
        },
        {
          value: 'ibu',
          text: 'Ibu'
        },
      ],
      selected: 'ibu'},
      placeHolderSearch: "Pilih provinsi",
      emptySlateText: 'Kosong'
    });
    
    expect(wrapper.vm.id).toBe('exampleModal');
    expect(wrapper.vm.chooseItem).toBe('ibu');
    expect(wrapper.props().items).toEqual([
      {
        value: 'bapak',
        text: 'Bapak'
      },
      {
        value: 'ibu',
        text: 'Ibu'
      },
    ]);
  });
  it("select data with setItem", () => {
    const wrapper = mount(Modal);
    
    wrapper.vm.setItem({value: "bapak", text: "Bapak"});
    expect(wrapper.vm.chooseItem).toBe('bapak');
  });
  it("close modal", () => {
    const wrapper = mount(Modal);
    window.$ = jest.fn().mockImplementation(() => {
        return {
          modal: jest.fn()
        }
    });
    wrapper.vm.closeModal()
  });
  it("custom class", () => {
    const wrapper = mount(Modal, {
      propsData: {
        activeSearch: true
      } 
    });
    expect(wrapper.vm.activeMaxHeightList()).toBe("overflow: scroll; max-height: 45vh;");
    expect(wrapper.vm.activeMaxHeightContent()).toBe("height: 73vh");
  })
  it("search key by input text", () => {
    const wrapper = mount(Modal, {
      propsData: {
        activeSearch: true
      } 
    });

    const event = {
      target: {
        value: "jakarta"
      }
    }
    wrapper.vm.searchKey(event)
    expect(wrapper.emitted('submit:key-search')[0][0]).toBe('jakarta');
  })
});