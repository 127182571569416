<template>
  <div class="p-erecruitment-error">
    <Navigation />
    <Container>
      <div class="row">
        <div class="col-sm-12 div-text-center step">
          <img
            alt="delete"
            src="../../../../assets/images/svg/vue/errorstate.svg"
          >
          <div class="col-sm-12 p-step div-text-center">
            <div class="title">
              Maaf
            </div>
            <div class="head">
              {{ $store.state.errorText }}
            </div>
          </div>
        </div>
      </div>
    </Container>
    <Footer />
  </div>
</template>

<script>
import Navigation from '../../organisms/Navigation';
import Container from '../../atoms/Container';
import Footer from '../../organisms/Footer';

export default {
  name: 'ErecruitmentError',
  components: {
    Navigation,
    Container,
    Footer
  }
};
</script>