import Application from './application';
import { mount } from '@vue/test-utils';

const wrapper = mount(Application, {
  stubs: [
    'router-link',
    'router-view',
  ],
})

describe('Application', () => {
  it("router-view exists", () => {
    expect(wrapper.find('router-view-stub').exists()).toBe(true);
  });
});