<template>
  <div class="p-erecruitment-step4">
    <Navigation />
    <Container>
      <div class="row">
        <div class="col-sm-12 div-text-center step">
          <template v-for="(item, idx) in steps">
            <StepButton
              :key="idx"
              :text="item.text"
              :done="item.done"
              :position="idx"
              :active="item.active"
              @click="moveStep"
            />
            <hr
              v-if="idx != 4"
              :key="item.text"
              class="line"
            >
          </template>
        </div>
        <div class="col-sm-12 p-step div-text-center">
          <div class="title">
            In which city of residence do you live?
          </div>
          <div class="head">
            Masukkan lokasi tempat Anda tinggal Saat ini
          </div>
          <div class="form-group form-content-center-2">
            <div
              class="form-control"
              style="cursor:pointer; border-radius: 10px;"
              @click="openProvinceModal()"
            >
              <div class="row">
                <div
                  class="col-sm-11 resize-11"
                  style="max-width: 85%; text-align: left;font-weight: 500; padding-top: 10px; letter-spacing: 0.22px;"
                >
                  {{ provinceName }}
                </div>
                <div
                  class="col-sm-1 resize-1"
                  style="padding-top: 10px;"
                >
                  <img
                    alt="delete"
                    src="../../../../assets/images/svg/vue/ic-caret-down.svg"
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="form-group form-content-center-2">
            <div
              class="form-control"
              style="cursor:pointer; border-radius: 10px;"
              @click="openCityModal()"
            >
              <div class="row">
                <div
                  class="col-sm-11 resize-11"
                  style="max-width: 85%; text-align: left;font-weight: 500; padding-top: 10px; letter-spacing: 0.22px;"
                >
                  {{ cityName }}
                </div>
                <div
                  class="col-sm-1 resize-1"
                  style="padding-top: 10px;"
                >
                  <img
                    alt="delete"
                    src="../../../../assets/images/svg/vue/ic-caret-down.svg"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal 
          :id="'cityModal'"
          :items="masterCities"
          :place-holder-search="'Pilih kota'"
          :empty-slate-text="'Kota yang kamu cari tidak ditemukan'"
          :active-search="true"
          :selected="$store.state.city_id"
          @submit:item="setCity"
          @submit:key-search="getResponseCity"
        />
        <Modal 
          :id="'provinceModal'"
          :items="masterProvinces"
          :place-holder-search="'Pilih provinsi'"
          :empty-slate-text="'Provinsi yang kamu cari tidak dapat ditemukan'"
          :active-search="true"
          :selected="$store.state.province_id"
          @submit:item="setProvince"
          @submit:key-search="getResponseProv"
        />
        <button
          class="button-fixed button-continue mt-4"
          :disabled="$store.state.city_id == null || $store.state.province_id == null"
          @click="continueStep()"
        >
          LANJUTKAN 
        </button>
      </div>
    </Container>
    <Footer />
  </div>
</template>

<script>
import Navigation from '../../organisms/Navigation';
import Container from '../../atoms/Container';
import StepButton from '../../atoms/StepButton';
import Footer from '../../organisms/Footer';
import axios from 'axios';
import Modal from '../../organisms/Modal';

export default {
  name: 'ErecruitmentStep4',
  components: {
    Navigation,
    Container,
    Footer,
    StepButton,
    Modal
  },
  props: {
    outside:  {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      provinceName: "",
      cityName: "",
      error: true,
      masterCities: [],
      kdProv: null,
      masterProvinces: [],
      steps: [
        {
          text: 'STEP 1',
          active: false,
          done: true,
          component: 'ErecruitmentStep1'
        },
        {
          text: 'STEP 2',
          active: false,
          done: true,
          component: 'ErecruitmentStep2'
        },
        {
          text: 'STEP 3',
          active: false,
          done: true,
          component: 'ErecruitmentStep3'
        },
        {
          text: 'STEP 4',
          active: true,
          done: false,
           component: 'ErecruitmentStep4'
        },
        {
          text: 'STEP 5',
          active: false,
          done: false,
           component: 'ErecruitmentStep5'
        }],
    }
  },
  created() {
    if (this.$store.state.full_name == "") {
      if (this.$route.query.utm_source || this.$route.query.utm_medium  || this.$route.query.utm_campaign ||  this.$route.query.ref) {
      this.$router.push({ name: 'ErecruitmentStep1' ,
        query: { 
          utm_source: this.$route.query.utm_source,
          utm_medium: this.$route.query.utm_medium,
          utm_campaign: this.$route.query.utm_campaign,
          ref: this.$route.query.ref}});
      } else {
        this.$router.push({ name: 'ErecruitmentStep1'});
      }
    }
    if (this.$route)  {
      if (this.$route.query) {
        this.$store.state.referral_key = this.$route.query.ref;
        this.$store.state.utm_source = this.$route.query.utm_source;
        this.$store.state.utm_medium = this.$route.query.utm_medium;
        this.$store.state.utm_campaign = this.$route.query.utm_campaign;
      }
    }
    
    this.provinceName = this.$store.state.province_name;
    this.cityName = this.$store.state.city_name;
    this.getResponseProv('');

  },
  methods: {
    moveStep(index) {
      if (this.steps[index].done) {
        if (this.$route)  {
          if (this.$route.query) {
            this.$router.push({ name: this.steps[index].component, query: { 
            utm_source: this.$route.query.utm_source,
            utm_medium: this.$route.query.utm_medium,
            utm_campaign: this.$route.query.utm_campaign,
            ref: this.$route.query.ref}});
          } else {
            this.$router.push({ name: this.steps[index].component});
          }
        } 
      }
    },
    getResponseProv(keyName) {
      axios.get(`/api/master/cities`, {
        params: {
          q: keyName,
          status_adm: 'PROV'
        },
      }).then(({ data }) => {
        this.masterProvinces = [];
        data.data.forEach((d) => { 
          this.masterProvinces.push({
            value: d.id,
            text: d.attributes.nama,
            kd_prov: d.attributes.kd_prov
          })
        });
      });
    },
    openCityModal() {
      if (this.$store.state.province_id) {
        $("#cityModal").modal('toggle');
      }
    },
    getResponseCity(keyName) {
      if (this.$store.state.province_id) {
        axios.get(`/api/master/cities`, {
          params: {
            q: keyName,
            filter_by: 'kd_prov',
            filter_value: this.kdProv,
            status_adm: 'KABU'
          },
        }).then(({ data }) => {
          this.masterCities = [];
          data.data.forEach((d) => { 
            this.masterCities.push({
              value: d.id,
              text: d.attributes.nama
            })
          });
        });
      }
    },
    openProvinceModal() {
      $("#provinceModal").modal('toggle');
    },
    setProvince(province) {
      this.$store.state.province_name = province.text;
      this.provinceName = province.text;
      this.kdProv = province.kd_prov;
      this.$store.state.province_id = province.value;
      this.getResponseCity('');
      this.cityName = "Pilih kota";
      this.$store.state.city_id = null;
    },
    setCity(city) {
      this.$store.state.city_name = city.text;
      this.$store.state.city_id = city.value;
      this.cityName = city.text;
    },
    continueStep() {
      if (this.$route)  {
        if (this.$route.query) {
          this.$router.push({ name: 'ErecruitmentStep5' ,
            query: { 
              utm_source: this.$route.query.utm_source,
              utm_medium: this.$route.query.utm_medium,
              utm_campaign: this.$route.query.utm_campaign,
              ref: this.$route.query.ref}});
        } else {
          this.$router.push({ name: 'ErecruitmentStep5'});
        }
      }
    },
  }
};
</script>