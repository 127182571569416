import Step4 from '../../pages/erecruitment/step4';
import { mount } from '@vue/test-utils';

const axiosMockAdapter = require('axios-mock-adapter')
const axios = require('axios')

const axiosMock = new axiosMockAdapter(axios, {delayResponse: 200})

axiosMock.onGet('/api/master/cities').reply(200, {
  data: [{ 
    id: 1,
    type: "master",
    attributes:
    {kd_prov: "11",
     kd_kab: "01", 
     kd_kec: "",
     kd_desa: "", 
     nama: "ACEH SELATAN", 
     status_adm: "KABU"} 
  }],
})

const mock = jest.fn().mockReturnValueOnce(true);

const $router = {
  path: '/some/path',
  currentRoute: {
    name: 'Main',
  },
  push: mock,
};

const $route = {
  query: {
    utm_medium: "1",
    utm_campaign: '2',
    utm_source: '3'
  }
}

const $store  = { state: { 
  full_name: '',
  salutation: "bapak",
  mobile_number: '',
  master_agency_experience_id: '',
  city_id: null,
  date_of_birth: '',
  province_name: '',
  city_name: '',
}}

const wrapper = mount(Step4, {
  mocks: {
    $router,
    $store,
    $route 
  }
});

describe('Step4', () => {
  it("init default data", () => {
    expect(wrapper.vm.provinceName).toBe("");
    expect(wrapper.vm.cityName).toBe("");
    expect(wrapper.vm.masterCities).toStrictEqual([]);
    expect(wrapper.vm.masterProvinces).toStrictEqual([]);
    expect(wrapper.vm.steps).toStrictEqual([
      {
        text: 'STEP 1',
        active: false,
        done: true,
        component: 'ErecruitmentStep1'
      },
      {
        text: 'STEP 2',
        active: false,
        done: true,
        component: 'ErecruitmentStep2'
      },
      {
        text: 'STEP 3',
        active: false,
        done: true,
        component: 'ErecruitmentStep3'
      },
      {
        text: 'STEP 4',
        active: true,
        done: false,
        component: 'ErecruitmentStep4'
      },
      {
        text: 'STEP 5',
        active: false,
        done: false,
        component: 'ErecruitmentStep5'
      }]);
    expect(wrapper.vm.cityName).toEqual($store.state.city_name);
    expect(wrapper.vm.provinceName).toEqual($store.state.province_name);
  });
  it("setProvince", () => {
    wrapper.vm.setProvince({value: 'jakarta', text: 'Jakarta'})
    expect(wrapper.vm.provinceName).toEqual($store.state.province_name);
  });
  it("setCity", () => {
    wrapper.vm.setCity({value: 'jakarta_selatan', text: 'Jakarta Selatan'})
    expect(wrapper.vm.cityName).toEqual($store.state.city_name);
  });
  it("move step from step 4 to step 1", () => { 
    wrapper.vm.steps[1].done = true
    wrapper.vm.moveStep(1)
    wrapper.vm.steps[1].done = false
    wrapper.vm.moveStep(1)
  });
  it("continuestep", () => {
    wrapper.vm.continueStep()
  })
});