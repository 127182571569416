import Step3 from '../../pages/erecruitment/step3';
import { mount } from '@vue/test-utils';

const mock = jest.fn().mockReturnValueOnce(true);
const $route = {
  query: {
    utm_medium: "1",
    utm_campaign: '2',
    utm_source: '3'
  }
}
const $router = {
  path: '/some/path',
  currentRoute: {
    name: 'Main',
  },
  push: mock,
};

const $store  = { state: { full_name: "", salutation: "bapak", mobile_number: "", master_agency_experience_id: "", city_id: null, date_of_birth: "" } }

const wrapper = mount(Step3, {
  mocks: {
    $router,
    $store,
    $route
  }
});

describe('Step3', () => {
  it("init default data", () => {
    expect(wrapper.vm.error).toBe(false);
    expect(wrapper.vm.errorText).toBe("");
    expect(wrapper.vm.formData).toStrictEqual({
      phoneNumber: "",
    });
    expect(wrapper.vm.steps).toStrictEqual([
      {
        text: 'STEP 1',
        active: false,
        done: true,
        component: 'ErecruitmentStep1',
      },
      {
        text: 'STEP 2',
        active: false,
        done: true,
        component: 'ErecruitmentStep2',
      },
      {
        text: 'STEP 3',
        active: true,
        done: false,
        component: 'ErecruitmentStep3',
      },
      {
        text: 'STEP 4',
        active: false,
        done: false,
        component: 'ErecruitmentStep4',
      },
      {
        text: 'STEP 5',
        active: false,
        done: false,
        component: 'ErecruitmentStep5',
      }]);
    expect(wrapper.vm.formData.phoneNumber).toEqual($store.state.mobile_number);
  });

  it("init created", () => {
    const $store  = { state: { full_name: "Bunda", salutation: "bapak", mobile_number: "087876440008", master_agency_experience_id: "", city_id: null, date_of_birth: "2020-02-01" } }

    const wrapper = mount(Step3, {
      mocks: {
        $router,
        $store
      }
    });

    expect($store.state.mobile_number).toEqual("087876440008");
  });

  it("move step from step 3 to step 1", () => { 
    wrapper.vm.steps[1].done = true
    wrapper.vm.moveStep(1)
    wrapper.vm.steps[1].done = false
    wrapper.vm.moveStep(1)
  });

  it("continue step", () => { 
    wrapper.vm.continueStep();
    expect(wrapper.vm.error).toBe(true);
    expect(wrapper.vm.errorText).toBe("Minimum Input Nomor Handphone adalah 8 karakter dan Maksimum Input Nomor Handphone adalah 14 karakter.");

    const event = {
      target: {
        value: '0878762229995'
      }
    }

    wrapper.vm.checkPhoneNumber(event);
    wrapper.vm.continueStep();
    expect(wrapper.vm.error).toBe(false);
    expect(wrapper.vm.errorText).toBe("");
  });

  it("checkPhoneNumber validation", () => { 
    let event = {
      target: {
        value: '0878762229995'
      }
    }

    wrapper.vm.checkPhoneNumber(event);
    wrapper.vm.continueStep();
    expect(wrapper.vm.error).toBe(false);
    expect(wrapper.vm.errorText).toBe("");

    event = {
      target: {
        value: null
      }
    }

    wrapper.vm.checkPhoneNumber(event);
    expect(wrapper.vm.error).toBe(true);
    expect(wrapper.vm.errorText).toBe("Nomor handphone harus diisi");

    event = {
      target: {
        value: '023'
      }
    }

    wrapper.vm.checkPhoneNumber(event);
    expect(wrapper.vm.error).toBe(true);
    expect(wrapper.vm.errorText).toBe("Minimum Input Nomor Handphone adalah 8 karakter dan Maksimum Input Nomor Handphone adalah 14 karakter.");

    // delete -  & (symbol)
    event = {
      target: {
        value: '&112-'
      }
    }

    wrapper.vm.checkPhoneNumber(event);
    expect(event.target.value).toBe('112');
    expect(wrapper.vm.error).toBe(true);
    expect(wrapper.vm.errorText).toBe("Minimum Input Nomor Handphone adalah 8 karakter dan Maksimum Input Nomor Handphone adalah 14 karakter.");
  });
});