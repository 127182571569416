<template>
  <div class="p-erecruitment-step1">
    <Navigation />
    <Container>
      <div class="row">
        <div class="col-sm-12 div-text-center step">
          <template v-for="(item, idx) in steps">
            <StepButton
              :key="idx"
              :text="item.text"
              :done="item.done"
              :position="idx"
              :active="item.active"
              @click="moveStep"
            />
            <hr
              v-if="idx != 4"
              :key="item.text"
              class="line"
            >
          </template>
        </div>
        <div class="col-sm-12 p-step">
          <div class="title pt-5">
            Hi! Tell us your full name
          </div>
          <div class="head">
            Masukkan Nama Lengkap Anda
          </div>
          <div class="input-group mt-4 retangle-step pb-3">
            <div
              class="input-group-prepend"
              data-toggle="modal"
              data-target="#exampleModal"
              style="cursor:pointer"
            >
              <div class="input-group-text"> 
                <span
                  class="first-letter"
                  style="width: 50px; text-align:left;"
                >
                  {{ getValueText(formData.salutation)}}
                </span>
                <img
                  alt="delete"
                  src="../../../../assets/images/svg/vue/ic-caret-down.svg"
                >
              </div>
            </div>
            <input
              id="inlineFormInputGroup"
              type="text"
              class="form-control form-control-step-3"
              placeholder="Nama lengkap"
              :value="$store.state.full_name"
              @keyup="setLeadName"
            >
          </div>
          <div
            v-if="error"
            class="text-error row"
          >
            <div class="col-sm-1 resize-1">
              <img
                src="../../../../assets/images/svg/vue/ic-warning.svg"
                style="padding-right: 10px;"
              >
            </div>
            <div class="col-sm-11 resize-11">
              {{ errorText }}
            </div>
          </div>
        </div>
        <button
          class="button-fixed button-continue mt-3 "
          :disabled="error"
          @click="continueStep()"
        >
          LANJUTKAN
        </button>
      </div>
      <Modal 
        :items="masterSalutations"
        :class="'step-1-top-margin'"
        :selected="$store.state.salutation"
        @submit:item="setSalutation"
      />
    </Container>
    <Footer />
  </div>
</template>

<script>
import Navigation from '../../organisms/Navigation';
import Container from '../../atoms/Container';
import StepButton from '../../atoms/StepButton';
import Footer from '../../organisms/Footer';
import Modal from '../../organisms/Modal';

export default {
  name: 'ErecruitmentStep1',
  components: {
    Navigation,
    Container,
    Footer,
    StepButton,
    Modal
  },
  props: {
    outside:  {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      error: false,
      errorText: '',
      masterSalutations: [
      {
        value: 'bapak',
        text: 'Mr'
      },
      {
        value: 'ibu',
        text: 'Mrs'
      }],
      formData: {
        full_name: "",
        salutation: "bapak",
      },
      steps: [
        {
          text: 'STEP 1',
          active: true,
          done: false,
        },
        {
          text: 'STEP 2',
          active: false,
          done: false,
        },
        {
          text: 'STEP 3',
          active: false,
          done: false,
        },
        {
          text: 'STEP 4',
          active: false,
          done: false,
        },
        {
          text: 'STEP 5',
          active: false,
          done: false
        }],
    }
  },
  created() {
    this.formData.salutation = this.$store.state.salutation;
    if (this.$route)  {
      if (this.$route.query) {
        this.$store.state.referral_key = this.$route.query.ref;
        this.$store.state.utm_source = this.$route.query.utm_source;
        this.$store.state.utm_medium = this.$route.query.utm_medium;
        this.$store.state.utm_campaign = this.$route.query.utm_campaign;
      }
    }
  },
  methods: {
    moveStep(index) {
      if (this.steps[index].done) {
        if (this.$route) {
          if (this.$route.query) {
            this.$router.push({ name: this.steps[index].component,query: { 
            utm_source: this.$route.query.utm_source,
            utm_medium: this.$route.query.utm_medium,
            utm_campaign: this.$route.query.utm_campaign,
            ref: this.$route.query.ref}});
          } else {
            this.$router.push({ name: this.steps[index].component});
          }
        } 
      }
    },
    continueStep() {
      if (this.$store.state.full_name == '' || !this.$store.state.full_name.replace(/\s/g, '').length) {
        this.error = true;
         this.errorText = 'Nama lengkap harus diisi';
      } else {
        this.error = false;
        if (this.$route.query.utm_source || this.$route.query.utm_medium  || this.$route.query.utm_campaign ||  this.$route.query.ref) {
          this.$router.push({ name: 'ErecruitmentStep2' ,
          query: { 
            utm_source: this.$route.query.utm_source,
            utm_medium: this.$route.query.utm_medium,
            utm_campaign: this.$route.query.utm_campaign,
            ref: this.$route.query.ref}});
        } else {
          this.$router.push({ name: 'ErecruitmentStep2'});
        }
      }
    },
    setLeadName(event) {
      var temp =  event.target.value.replace(/[^a-zA-Z ]+/g, '');
      if (temp == ' ') {
        temp = '';
      }
      this.$store.state.full_name =  temp;
      event.target.value = temp;
      if (this.$store.state.full_name.length < 3 || this.$store.state.full_name.length > 100) {
        this.error = true;
        this.errorText = 'Nama lengkap harus minimal 3 karakter dan tidak boleh melebihi 100 karakter';
      } else {
        this.error = false;
        this.errorText = ''
      }
      this.$store.state.full_name = event.target.value;
    },
    setSalutation(item) {
      this.formData.salutation = item.value;
      this.$store.state.salutation = item.value;
    },
    getValueText(salutation) {
      if (salutation == 'bapak') {
        return 'Mr'
      }
      return 'Mrs'
    }
  }
};
</script>