import Vue from 'vue'
import Vuex from 'vuex';

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    full_name: '',
    salutation: 'bapak',
    mobile_number: '',
    master_agency_experience_id: '',
    city_id: null,
    province_id: null,
    province_name: null,
    city_name: '',
    date_of_birth: '',
    referral_key: '',
    errorText: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: ''
  }
});

export default store;