<template>
  <div class="c-footer">
    <div class="row">
      <div class="col-sm-12" style="text-align: center; padding: 0 10%;">
        <div>PT Asuransi Jiwa Sequis Life berizin dan diawasi oleh Otoritas Jasa Keuangan</div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Footer'
};
</script>