<template>
  <div
    :id="id"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-bottom"
      role="document"
    >
      <div
        class="modal-content"
        :style="activeMaxHeightContent()"
      >
        <div class="modal-header">
          <a
            data-dismiss="modal"
            aria-label="Close"
            class="close-modal"
          >
            <img
              alt="delete"
              src="../../../../assets/images/svg/vue/times.svg"
            >
          </a>
        </div>
        <div
          class="modal-body"
          style="text-align: left;"
        >
          <div
            v-if="activeSearch"
            class="row"
            style="text-align:center;"
            @keyup="searchKey($event)"
          >
            <div class="form-group col-sm-12">
              <input
                type="text"
                class="form-control retangle mb-2"
                style=" border-radius: 15px; margin:auto; width: 93%; height: 50px;"
                :placeholder="placeHolderSearch"
              >
            </div>
          </div>
          <div :style="activeMaxHeightList()">
            <ul class="list-group">
              <li
                v-for="(item, key) in items"
                :key="key"
                class="list-group-item"
                :class="{
                  'active': chooseItem == item.value
                }"
                @click="setItem(item)"
              >
                <div class="row">
                  <div class="col-sm-11 resize-11">
                    {{ item.text }}
                  </div>
                  <div class="col-sm-1 resize-1">
                    <img
                      v-if="chooseItem == item.value"
                      src="../../../../assets/images/svg/vue/selected.svg"
                      class="image-padding"
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div 
            v-if="items.length == 0"
            id="over"
            class="ex2" 
            style="height: 450px; width: 100%; position:absolute;"
          >
            <div class="div-text-center">
              <img
                alt="banner"
                src="../../../../assets/images/svg/vue/emptystate-kota.svg"
                class="img-responsive"
              >
            </div>
            <div class="div-text-center">
              Oppss
            </div>
            <div class="div-text-center">
              {{ emptySlateText }}
            </div>
          </div>
          <button
            class="button-fixed button-continue mt-3 button-modal"
            @click="closeModal()"
          >
            PILIH
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Modal',
  props: {
    placeHolderSearch: {
      type: String,
      default: "",
    },
    emptySlateText: {
      type: String,
      default: "",
    },
    activeSearch: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "exampleModal",
    },
    items: {
      type: Array,
      default: () => []
    },
    selected: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      chooseItem: null,
    }
  },
  created() {
    this.chooseItem = this.selected;
  },
  methods: {
    setItem(item) {
      this.chooseItem = item.value;
      this.$emit('submit:item', item);
    },
    closeModal() {
      $(`#${this.id}`).modal('toggle');
    },
    activeMaxHeightList() {
      if (this.activeSearch) {
        return "overflow: scroll; max-height: 45vh;";
      }
      return '';
    },
    activeMaxHeightContent() {
      if (this.activeSearch) {
        return 'height: 73vh';
      }
      return '';
    },
    searchKey(event) {
      this.$emit('submit:key-search', event.target.value);
    }
  }
};
</script>