<template>
  <div class="p-erecruitment-step3">
    <Navigation />
    <Container>
      <div class="row">
        <div class="col-sm-12 div-text-center step">
          <template v-for="(item, idx) in steps">
            <StepButton
              :key="idx"
              :text="item.text"
              :done="item.done"
              :position="idx"
              :active="item.active"
              @click="moveStep"
            />
            <hr
              v-if="idx != 4"
              :key="item.text"
              class="line"
            >
          </template>
        </div>
        <div class="col-sm-12 p-step div-text-center">
          <div class="title">
           May we know your phone number?
          </div>
          <div class="head">
            Sebaiknya masukan nomor telepon yang terhubung dengan WhatsApp Anda
          </div>
          <div class="input-group mt-4 retangle-step pb-3">
            <div
              class="input-group-prepend"
              data-toggle="modal"
              data-target="#exampleModal"
              style="cursor:pointer"
            >
              <div class="input-group-text"> 
                <span
                  class="first-letter"
                  style="width: 50px; text-align:left;"
                >
                  +62
                </span>
              </div>
            </div>
            <input
              id="inlineFormInputGroup"
              type="text"
              class="form-control form-control-step-3"
              placeholder="81xxx xxx xxx"
              :value="$store.state.mobile_number"
              @keyup="checkPhoneNumber"
            >
          </div>
          <div
            v-if="error"
            class="text-error pt-2"
          >
            <img
              src="../../../../assets/images/svg/vue/ic-warning.svg"
              style="padding-right: 10px;"
            >
            {{ errorText }}
          </div>
        </div>
        <button
          class="button-fixed button-continue mt-3"
          @click="continueStep()"
        >
          LANJUTKAN
        </button>
      </div>
    </Container>
    <Footer />
  </div>
</template>

<script>
import Navigation from '../../organisms/Navigation';
import Container from '../../atoms/Container';
import StepButton from '../../atoms/StepButton';
import Footer from '../../organisms/Footer';

export default {
  name: 'ErecruitmentStep3',
  components: {
    Navigation,
    Container,
    Footer,
    StepButton
  },
  props: {
    outside:  {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      error: false,
      errorText: "",
      formData: {
        phoneNumber: "",
      },
      steps: [
        {
          text: 'STEP 1',
          active: false,
          done: true,
          component: 'ErecruitmentStep1',
        },
        {
          text: 'STEP 2',
          active: false,
          done: true,
          component: 'ErecruitmentStep2',
        },
        {
          text: 'STEP 3',
          active: true,
          done: false,
          component: 'ErecruitmentStep3',
        },
        {
          text: 'STEP 4',
          active: false,
          done: false,
          component: 'ErecruitmentStep4',
        },
        {
          text: 'STEP 5',
          active: false,
          done: false,
          component: 'ErecruitmentStep5',
        }],
    }
  },
  created() {
    if (this.$store.state.full_name == "") {
      if (this.$route || this.$route.query) {
      this.$router.push({ name: 'ErecruitmentStep1' ,
        query: { 
          utm_source: this.$route.query.utm_source,
          utm_medium: this.$route.query.utm_medium,
          utm_campaign: this.$route.query.utm_campaign,
          ref: this.$route.query.ref}});
      } else {
        this.$router.push({ name: 'ErecruitmentStep1'});
      }
    }
    if (this.$route)  {
      if (this.$route.query) {
        this.$store.state.referral_key = this.$route.query.ref;
        this.$store.state.utm_source = this.$route.query.utm_source;
        this.$store.state.utm_medium = this.$route.query.utm_medium;
        this.$store.state.utm_campaign = this.$route.query.utm_campaign;
      }
    }
  },
  methods: {
    moveStep(index) {
      if (this.steps[index].done) {
        if (this.$route)  {
          if (this.$route.query) {
            this.$router.push({ name: this.steps[index].component, query: { 
            utm_source: this.$route.query.utm_source,
            utm_medium: this.$route.query.utm_medium,
            utm_campaign: this.$route.query.utm_campaign,
            ref: this.$route.query.ref}});
          } else {
            this.$router.push({ name: this.steps[index].component});
          }
        } 
      }
    },
    continueStep() {
      if (this.$store.state.mobile_number == "" || (this.$store.state.mobile_number.length < 8 || this.$store.state.mobile_number.length > 14)) {
        this.error = true;
        this.errorText = 'Minimum Input Nomor Handphone adalah 8 karakter dan Maksimum Input Nomor Handphone adalah 14 karakter.';
      } else {
        this.error = false;
        if (this.$route.query.utm_source || this.$route.query.utm_medium  || this.$route.query.utm_campaign ||  this.$route.query.ref) {
        this.$router.push({ name: 'ErecruitmentStep4' ,
          query: { 
            utm_source: this.$route.query.utm_source,
            utm_medium: this.$route.query.utm_medium,
            utm_campaign: this.$route.query.utm_campaign,
            ref: this.$route.query.ref}});
        } else {
          this.$router.push({ name: 'ErecruitmentStep4'});
        }
      }
    },
    checkPhoneNumber(event) {
      if (event.target.value == null ) {
        this.error = true;
        this.errorText = 'Nomor handphone harus diisi';
        return
      }
      var temp =  event.target.value.replace( /[^0-9]/g,'');
      this.formData.phoneNumber  = temp;
      this.$store.state.mobile_number =  temp;
      event.target.value = temp;
      if (this.formData.phoneNumber != null && (this.formData.phoneNumber.length < 8 || this.formData.phoneNumber.length > 14) ) {
        this.error = true;
        this.errorText = 'Minimum Input Nomor Handphone adalah 8 karakter dan Maksimum Input Nomor Handphone adalah 14 karakter.';
      } else {
        this.error = false;
        this.errorText = ''
      }
    },
  }
};
</script>