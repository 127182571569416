import Home from '../../pages/erecruitment/home';
import { mount } from '@vue/test-utils';

const mock = jest.fn().mockImplementation(() => Promise.resolve());

const $route = {
  query: {
    utm_medium: "1",
    utm_campaign: '2',
    utm_source: '3'
  }
};
const $router = {
  path: '/some/path',
  currentRoute: {
    name: 'Main',
  },
  push: mock,
};

const $store  = { state: { 
  full_name: '',
  salutation: "bapak",
  mobile_number: '0823362616161',
  master_agency_experience_id: '1',
  city_id: '1',
  date_of_birth: '1999-01-01',
  province_name: 'Jakarta',
  city_name: '',
  referral_key: '',
  utm_medium: '1',
  utm_campaign: '2',
  utm_source: '3',
}}

const wrapper = mount(Home, {
  mocks: {
    $router,
    $store,
    $route
  }
});

describe('Home', () => {
  it("check methods search", () => {
    wrapper.vm.search()
  });
});