import Step2 from '../../pages/erecruitment/step2';
import { mount } from '@vue/test-utils';

const mock = jest.fn().mockReturnValueOnce(true);
const $route = {
  query: {
    utm_medium: "1",
    utm_campaign: '2',
    utm_source: '3'
  }
}
const $router = {
  path: '/some/path',
  currentRoute: {
    name: 'Main',
  },
  push: mock,
};

const $store  = { state: { full_name: "", salutation: "bapak", mobile_number: "", master_agency_experience_id: "", city_id: null, date_of_birth: "" } }

const wrapper = mount(Step2, {
  mocks: {
    $router,
    $store,
    $route
  }
});

describe('Step1', () => {
  it("init default data", () => {
    expect(wrapper.vm.error).toBe(false)
    expect(wrapper.vm.textError).toBe("")
    expect(wrapper.vm.steps).toStrictEqual([
    {
      text: 'STEP 1',
      active: false,
      done: true,
      component: 'ErecruitmentStep1',
    },
    {
      text: 'STEP 2',
      active: true,
      done: false,
      component: 'ErecruitmentStep2',
    },
    {
      text: 'STEP 3',
      active: false,
      done: false,
      component: 'ErecruitmentStep3',
    },
    {
      text: 'STEP 4',
      active: false,
      done: false,
      component: 'ErecruitmentStep4',
    },
    {
      text: 'STEP 5',
      active: false,
      done: false,
      component: 'ErecruitmentStep5',
    }]);
  });
  it("init created", () => {
    const $store  = { state: { full_name: "Bunda", salutation: "bapak", mobile_number: "", master_agency_experience_id: "", city_id: null, date_of_birth: "2020-02-01" } }

    const wrapper = mount(Step2, {
      mocks: {
        $router,
        $store
      }
    });
  });

  it("move step from step 2 to step 1", () => { 
    wrapper.vm.steps[1].done = true
    wrapper.vm.moveStep(1)
    wrapper.vm.steps[1].done = false
    wrapper.vm.moveStep(1)
  });

  it("continue step", () => { 
    wrapper.vm.day = "";
    wrapper.vm.continueStep();
    expect(wrapper.vm.error).toBe(true);
    expect(wrapper.vm.textError).toBe("Field harus diisi");

    $store.state.date_of_birth = "1999-02-03"
    wrapper.vm.continueStep();
  });
});