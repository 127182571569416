<template>
  <div class="p-erecruitment-home">
    <Navigation />
    <Container>
      <div class="row">
        <div class="col-sm-6">
          <div class="row">
            <div class="title-header">
              Welcome to XLRT ! 
            </div>
          </div>
          <div class="row">
            <div class="title-content">
              Join with more than 1000 
            </div>
            <div class="title-content">
              Youngsters and start to
            </div>
            <div class="title-content">
              Excel-lerate Your Move
            </div>
          </div>
          <div
            class="row"
          >
            <div class="title-content">
              <button
                type="button"
                class="base-button"
                @click="search()"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <img
            alt="banner"
            src="../../../../assets/images/svg/vue/landing-erecruitment.svg"
            class="img-responsive img-responsive-logo padding-img"
            style="width:100%;padding-top:50px;"
          >
        </div>
      </div>
    </Container>
    <Footer />
  </div>
</template>

<script>
import Navigation from '../../organisms/Navigation';
import Container from '../../atoms/Container';
import Footer from '../../organisms/Footer';

export default {
  name: 'ErecruitmentHome',
  components: {
    Navigation,
    Container,
    Footer
  },
  props: {
    outside:  {
      type: Object,
      default: () => {},
    }
  },
  created() {
    this.$store.state.utm_source = this.$route.query.utm_source;
    this.$store.state.utm_medium = this.$route.query.utm_medium;
    this.$store.state.utm_campaign = this.$route.query.utm_campaign;
    this.$store.state.referral_key = this.$route.query.ref;
  },
  methods: {
    search() {
      if (this.$route.query.utm_source || this.$route.query.utm_medium  || this.$route.query.utm_campaign ||  this.$route.query.ref) {
        this.$router.push({ name: 'ErecruitmentStep1' ,
        query: { 
          utm_source: this.$route.query.utm_source,
          utm_medium: this.$route.query.utm_medium,
          utm_campaign: this.$route.query.utm_campaign,
          ref: this.$route.query.ref}});
      } else {
        this.$router.push({ name: 'ErecruitmentStep1'});
      }
    }
  }
};
</script>