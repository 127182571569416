import Container from '../../atoms/Container';
import { shallowMount } from '@vue/test-utils';

const wrapper = shallowMount(Container, {
  slots: {
    default: '<div class="fake-msg"></div>'
  }});

describe('Container', () => {
  it('has default class', () => {
    expect(wrapper.find('.c-container').exists()).toBe(true);
  });
  it('has slot', () => {
    expect(wrapper.find('.fake-msg').exists()).toBe(true);
  });
});