<template>
  <div class="o-navigation">
    <div class="header--wrapper">
      <div class="header--primary">
        <div class="row">
          <div class="col-sm-12">
            <div
              id="menu"
              class="header__menu-nav"
            >
              <a
                class="icon"
                @click="openbar()"
              >
                <img
                  alt="burger-menu"
                  src="../../../../assets/images/svg/vue/burger-menu.svg"
                >
              </a>
              <a href="https://www.sequis.co.id/">
                <img
                  class="logo-nav"
                  alt="Sequis logo"
                  src="../../../../assets/images/svg/vue/logo-sequis.svg"
                  target="_blank"
                  height="40"
                >
              </a>
              <div class="header__menu-center">
                <ul class="header__menu-group mb-0">
                  <li
                    v-for="(link, key) in links"
                    :key="key"
                    class="header__menu-group-list"
                  >
                    <a
                      :href="link.path"
                      target="_blank"
                    >
                      <span class="mr-5 icon-header">
                        {{ link.text }}
                      </span>
                      <span class="u-v-middle" />
                    </a>
                  </li>
                </ul>
              </div>
              <div class="icon-sosmed">
                <ul class="mb-0">
                  <li
                    v-for="(item, key) in sosmeds"
                    :key="key"
                    class="header__menu-group-list"
                  >
                    <a
                      :href="item.url"
                      target="_blank"
                    >
                      <img
                        :src="item.imageUrl"
                        height="20"
                      >
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            id="myTopnav"
            class="topnav"
          >
            <a 
              v-for="(link, key) in links"
              :key="key"
              :href="link.path"
              target="_blank"
            >
              {{ link.text }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Navigation',
    data: function () {
      return {
        links:  [
          { path: 'https://www.sequis.co.id/id/tentang-sequis/life/profil-perusahaan', text: 'Tentang' },
          { path: 'https://www.sequis.co.id/id/contact-us', text: 'Hubungi kami' },
          { path: 'https://www.sequis.co.id/', text: 'Sequis.co.id' }
        ],
        sosmeds: [
          { imageUrl: require('../../../../assets/images/svg/vue/twitter.svg'), url: ' https://twitter.com/SequisOFFICIAL' },
          { imageUrl:  require('../../../../assets/images/svg/vue/facebook.svg'), url: 'https://www.facebook.com/sequislife/' },
          { imageUrl:  require('../../../../assets/images/svg/vue/instagram.svg'), url: ' https://www.instagram.com/sequisofficial/' },
          { imageUrl:  require('../../../../assets/images/svg/vue/youtube.svg'), url: 'https://www.youtube.com/ajsequislife' }
        ]
      }
    },
    methods: {
      openbar() {
        let x = document.getElementById("myTopnav");
        if (x.className === "topnav") {
          x.className += " responsive";
        } else {
          x.className = "topnav";
        }
        return x;
      },
    },
  }
</script>