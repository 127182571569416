<template>
  <div class="p-erecruitment-home">
    <Navigation />
    <Container>
      <div class="row">
        <div class="col-sm-6">
          <div class="row">
            <div class="title-header">
              Thank You ! 
            </div>
          </div>
          <div class="row">
            <div class="title-content center" >
             Buckle Up, because we are excel-lerating! 
            </div>
            <div class="title-content center">
              We are Thrilled to meet you and be one of Excel-lent leader
            </div>
             <div class="title-content center">
              Follow <a href="https://www.instagram.com/xlrt.id">@xlrt.id </a> get our latest info
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <img
            alt="banner"
            src="../../../../assets/images/svg/vue/thankyou.svg"
            class="img-responsive img-responsive-logo padding-img"
            style="width:100%;padding-top:45px;"
          >
        </div>
      </div>
    </Container>
    <Footer />
  </div>
</template>

<script>
import Navigation from '../../organisms/Navigation';
import Container from '../../atoms/Container';
import Footer from '../../organisms/Footer';

export default {
  name: 'ErecruitmentSuccess',
  components: {
    Navigation,
    Container,
    Footer
  }
};
</script>