<template>
  <div class="c-container">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Container',
};
</script>