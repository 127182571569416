import StepButton from '../../atoms/StepButton';
import { shallowMount } from '@vue/test-utils';

const wrapper = shallowMount(StepButton);

describe('StepButton', () => {
  it('has default button', () => {
    expect(wrapper.find('button').exists()).toBe(true);
    expect(wrapper.find('.active-step').exists()).toBe(false);
    expect(wrapper.find('.done').exists()).toBe(false);
  });
  it('have default props', () => {
    expect(wrapper.props().text).toBe(null);
    expect(wrapper.props().active).toBe(false);
    expect(wrapper.props().done).toBe(false);
    expect(wrapper.props().position).toBe(null);
  });
});