<template>
  <div id="Application">
    <router-view
      :outside="outside"
    />
  </div>
</template>

<script>
import VueRouter from 'vue-router';
import ErecruitmentHome from './pages/erecruitment/home';
import ErecruitmentStep1 from './pages/erecruitment/step1';
import ErecruitmentStep2 from './pages/erecruitment/step2';
import ErecruitmentStep3 from './pages/erecruitment/step3';
import ErecruitmentStep4 from './pages/erecruitment/step4';
import ErecruitmentStep5 from './pages/erecruitment/step5';
import ErecruitmentSuccess from './pages/erecruitment/success';
import ErecruitmentError from './pages/erecruitment/error';

const routes = [
  {
    path: '/recruitment',
    component: ErecruitmentHome,
    name: 'ErecruitmentHome'
  },
  {
    path: '/recruitment/step/1',
    component: ErecruitmentStep1,
    name: 'ErecruitmentStep1'
  },
  {
    path: '/recruitment/step/2',
    component: ErecruitmentStep2,
    name: 'ErecruitmentStep2'
  },
  {
    path: '/recruitment/step/3',
    component: ErecruitmentStep3,
    name: 'ErecruitmentStep3'
  },
  {
    path: '/recruitment/step/4',
    component: ErecruitmentStep4,
    name: 'ErecruitmentStep4'
  },
  {
    path: '/recruitment/step/5',
    component: ErecruitmentStep5,
    name: 'ErecruitmentStep5'
  },
  {
    path: '/recruitment/step/error',
    component: ErecruitmentError,
    name: 'ErecruitmentError'
  },
  {
    path: '/recruitment/step/thank-you',
    component: ErecruitmentSuccess,
    name: 'ErecruitmentSuccess'
  }];

const router = new VueRouter({
    mode: 'history',
    routes
  });

export default {
  name: 'Application',
  props: {
    outside:  {
      type: Object,
      default: () => {},
    }
  },
  router
};

</script>