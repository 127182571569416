import Step5 from '../../pages/erecruitment/step5';
import { mount } from '@vue/test-utils';

const axios = require('axios')
const mock = jest.fn().mockReturnValueOnce(true);
const $route = {
  query: {
    utm_medium: "1",
    utm_campaign: '2',
    utm_source: '3'
  }
}
jest.mock("axios");

axios.get.mockImplementation(() => Promise.resolve({ status: 200, data: [{
  id: 1,
  name_id: 'pernah'
}]}));


axios.post.mockImplementation(() => Promise.resolve({ status: 200, data:{} }));

const $router = {
  path: '/some/path',
  currentRoute: {
    name: 'Main',
  },
  push: mock,
};

const $store  = { state: { 
  full_name: '',
  salutation: "bapak",
  mobile_number: '0823362616161',
  master_agency_experience_id: '1',
  city_id: '1',
  date_of_birth: '1999-01-01',
  province_name: 'Jakarta',
  city_name: '',
  referral_key: '',
  utm_medium: '1',
  utm_campaign: '2',
  utm_source: '3',
}}

const wrapper = mount(Step5, {
  mocks: {
    $router,
    $store,
    $route 
  },
  propsData: {
    outside: {
      env: ''
    }
  }
});

describe('Step5', () => {
  it("init default data", () => {
    expect(wrapper.vm.chooseId).toBe(1);
    expect(wrapper.vm.relations).toStrictEqual([{
      id: 1,
      text: 'pernah'
    }])
    expect(wrapper.vm.formData).toStrictEqual({
      city_id: null,
      date_of_birth: '',
      full_name: '',
      master_agency_experience_id: null,
      phone_number: "",
      referral_key: "",
      salutation: ""
    });
    expect(wrapper.vm.steps).toStrictEqual([
      {
        text: 'STEP 1',
        active: false,
        done: true,
        component: 'ErecruitmentStep1'
      },
      {
        text: 'STEP 2',
        active: false,
        done: true,
        component: 'ErecruitmentStep2'
      },
      {
        text: 'STEP 3',
        active: false,
        done: true,
        component: 'ErecruitmentStep3'
      },
      {
        text: 'STEP 4',
        active: false,
        done: true,
        component: 'ErecruitmentStep4'
      },
      {
        text: 'STEP 5',
        active: true,
        done: false,
        component: 'ErecruitmentStep5'
      }]);
  });
  it("submit ERecuitment", () => {
    wrapper.vm.submitERecuitment()
    expect(wrapper.vm.formData).toStrictEqual({
      city_id: parseInt($store.state.city_id),
      date_of_birth: $store.state.date_of_birth,
      full_name: $store.state.full_name,
      master_agency_experience_id: $store.state.master_agency_experience_id,
      phone_number: $store.state.mobile_number,
      referral_key: $store.state.referral_key,
      salutation: $store.state.salutation,
      utm_medium: $store.state.utm_medium,
      utm_campaign: $store.state.utm_campaign,
      utm_source: $store.state.utm_source,
    })
  });

  it("move step from step 5 to step 1", () => { 
    wrapper.vm.steps[1].done = true
    wrapper.vm.moveStep(1)
    wrapper.vm.steps[1].done = false
    wrapper.vm.moveStep(1)
  });
  it("setExperience", () => {
    var relation = {
      id: '2',
      text: 'never done'
    }
    wrapper.vm.setExperience(relation)
    expect($store.state.master_agency_experience_id).toBe('2')
    expect(wrapper.vm.agencyExperienceName).toBe('never_done')
    expect(wrapper.vm.chooseId).toBe('2')
  });
  it("getResponseAgencyExperiences", () => {
    wrapper.vm.getResponseAgencyExperiences()
    expect(wrapper.vm.relations).toStrictEqual([{
      id: 1,
      text: 'pernah'
    }])
  });
  it("state fullname exists", () => {
    const $store  = { state: { 
      full_name: 'Sulaiman',
      salutation: "bapak",
      mobile_number: '0823362616161',
      master_agency_experience_id: '1',
      city_id: '1',
      date_of_birth: '1999-01-01',
      province_name: 'Jakarta',
      city_name: '',
      referral_key: '',
      utm_medium: '1',
      utm_campaign: '2',
      utm_source: '3'
    }}

    mount(Step5, {
      mocks: {
        $router,
        $store
      },
      propsData: {
        outside: {
          env: ''
        }
      }
    });
  });
});