import Step1 from '../../pages/erecruitment/step1';
import { mount } from '@vue/test-utils';

const mock = jest.fn().mockReturnValueOnce(true);

const $router = {
  path: '/some/path',
  currentRoute: {
    name: 'Main',
  },
  push: mock
};

const $route = {
  query: {
    ref: ""
  }
};

const $store  = { state: { full_name: "", salutation: "bapak", mobile_number: "", master_agency_experience_id: "", city_id: null, date_of_birth: "", referral_key: "" } }

const wrapper = mount(Step1, {
  mocks: {
    $router,
    $store,
    $route 
  }
});

describe('Step1', () => {
  it("init default data", () => {
    expect(wrapper.vm.error).toBe(false);
    expect(wrapper.vm.masterSalutations).toStrictEqual([
      {
        value: 'bapak',
        text: 'Mr'
      },
      {
        value: 'ibu',
        text: 'Mrs'
      }]);
    expect(wrapper.vm.formData).toStrictEqual({
      full_name: "",
      salutation: "bapak",
    });
    expect(wrapper.vm.steps).toStrictEqual([
      {
        text: 'STEP 1',
        active: true,
        done: false,
      },
      {
        text: 'STEP 2',
        active: false,
        done: false,
      },
      {
        text: 'STEP 3',
        active: false,
        done: false,
      },
      {
        text: 'STEP 4',
        active: false,
        done: false,
      },
      {
        text: 'STEP 5',
        active: false,
        done: false
      }]);
    expect(wrapper.vm.formData.salutation).toEqual($store.state.salutation);
  });
  it("move step from step 1 to step 2", () => { 
    wrapper.vm.steps[1].done = true
    wrapper.vm.moveStep(1)
    wrapper.vm.steps[1].done = false
    wrapper.vm.moveStep(1)
  });

  it("submit button continue", () => { 
    wrapper.vm.continueStep()
    expect(wrapper.vm.error).toBe(true)
    expect(wrapper.vm.errorText).toBe("Nama lengkap harus diisi")

    const event = {
      target: {
        value: "Susi"
      }
    }
    wrapper.vm.setLeadName(event)
    wrapper.vm.continueStep()
    expect(wrapper.vm.error).toBe(false)
  });

  it("validation leadname", () => { 
    const event = {
      target: {
        value: "Susi123"
      }
    }
    wrapper.vm.setLeadName(event)
    expect(wrapper.vm.$store.state.full_name).toBe("Susi")

    const event2 = {
      target: {
        value: "S"
      }
    }
    wrapper.vm.setLeadName(event2)
    expect(wrapper.vm.error).toBe(true)
    expect(wrapper.vm.errorText).toBe('Nama lengkap harus minimal 3 karakter dan tidak boleh melebihi 100 karakter')

    const event3 = {
      target: {
        value: " "
      }
    }
    wrapper.vm.setLeadName(event3)
    expect(wrapper.vm.$store.state.full_name).toBe('')
    expect(wrapper.vm.error).toBe(true)
    expect(wrapper.vm.errorText).toBe('Nama lengkap harus minimal 3 karakter dan tidak boleh melebihi 100 karakter')
  });

  it("set salutation", () => { 
    wrapper.vm.setSalutation({value: "ibu", text: "Mrs"})
    expect(wrapper.vm.formData.salutation).toBe("ibu")
    expect(wrapper.vm.$store.state.salutation).toBe("ibu")
  });
});