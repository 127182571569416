<template>
  <button
    :class="{
      'active-step': active ,
      'done': done && !active,
    }" 
    @click="$emit('click', position)"
  >
    <template v-if="active">
      <b>
        {{ text }}
      </b>
    </template>
  </button>
</template>

<script>

export default {
  name: 'StepButton',
  props: {
    text: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    done: {
      type: Boolean,
      default: false,
    },
    position: {
      type: Number,
      default: null,
    }
  },
};
</script>