<template>
  <div class="p-erecruitment-step5">
    <Navigation />
    <Container>
      <div class="row">
        <div class="col-sm-12 div-text-center step">
          <template v-for="(item, idx) in steps">
            <StepButton
              :key="idx"
              :text="item.text"
              :done="item.done"
              :position="idx"
              :active="item.active"
              @click="moveStep"
            />
            <hr
              v-if="idx != 4"
              :key="item.text"
              class="line"
            >
          </template>
        </div>
        <div class="col-sm-12 p-step div-text-center">
          <div class="title pt-5">
            Are you experienced in financial industry?
          </div>
          <div class="head">
            Pilih berdasarkan pengalaman Anda dalam bidang finansial
          </div>
          <div class="form-group pt-3">
            <div
              class="col-sm-12 form-step-5"
              style="padding-bottom: 10px; "
            >
              <div
                v-for="(relation, key) in relations"
                :key="key"
                class="form-check form-check-inline form-control retangle-5 form-control-5" 
                style="text-align:left; cursor:pointer;"
                @click="setExperience(relation)"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  :value="relation.id"
                  :checked="relation.id === chooseId"
                >
                <label
                  class="form-check-label ml-2"
                  for="inlineRadio1"
                >{{ relation.text }}</label>
              </div>
            </div>
          </div>
        </div>
        <button
          class="button-fixed button-continue"
          @click="submitERecuitment()"
        >
          LANJUTKAN
        </button>
      </div>
    </Container>
    <Footer />
  </div>
</template>

<script>
import Navigation from '../../organisms/Navigation';
import Container from '../../atoms/Container';
import StepButton from '../../atoms/StepButton';
import Footer from '../../organisms/Footer';
import axios from 'axios';

export default {
  name: 'ErecruitmentStep5',
  components: {
    Navigation,
    Container,
    Footer,
    StepButton
  },
  props: {
    outside:  {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      relations: [],
      errorText: "",
      formData: {
        city_id: null,
        date_of_birth: '',
        full_name: '',
        master_agency_experience_id: null,
        phone_number: "",
        referral_key: "",
        salutation: ""
      },
      chooseId: null,
      agencyExperienceName: "",
      steps: [
        {
          text: 'STEP 1',
          active: false,
          done: true,
          component: 'ErecruitmentStep1'
        },
        {
          text: 'STEP 2',
          active: false,
          done: true,
          component: 'ErecruitmentStep2'
        },
        {
          text: 'STEP 3',
          active: false,
          done: true,
          component: 'ErecruitmentStep3'
        },
        {
          text: 'STEP 4',
          active: false,
          done: true,
          component: 'ErecruitmentStep4'
        },
        {
          text: 'STEP 5',
          active: true,
          done: false,
          component: 'ErecruitmentStep5'
        }],
    }
  },
  created() {
    if(this.$store.state.full_name == "") {
      if (this.$route.query.utm_source || this.$route.query.utm_medium  || this.$route.query.utm_campaign ||  this.$route.query.ref) {
      this.$router.push({ name: 'ErecruitmentStep1' ,
        query: { 
          utm_source: this.$route.query.utm_source,
          utm_medium: this.$route.query.utm_medium,
          utm_campaign: this.$route.query.utm_campaign,
          ref: this.$route.query.ref}});
      } else {
        this.$router.push({ name: 'ErecruitmentStep1'});
      }
    }
    if (this.$route)  {
      if (this.$route.query) {
        this.$store.state.referral_key = this.$route.query.ref;
        this.$store.state.utm_source = this.$route.query.utm_source;
        this.$store.state.utm_medium = this.$route.query.utm_medium;
        this.$store.state.utm_campaign = this.$route.query.utm_campaign;
      }
    }
    
    this.getResponseAgencyExperiences();
  },
  methods: {
    moveStep(index) {
      if (this.steps[index].done) {
        if (this.$route)  {
          if (this.$route.query) {
            this.$router.push({ name: this.steps[index].component, query: { 
            utm_source: this.$route.query.utm_source,
            utm_medium: this.$route.query.utm_medium,
            utm_campaign: this.$route.query.utm_campaign,
            ref: this.$route.query.ref}});
          } else {
            this.$router.push({ name: this.steps[index].component});
          }
        } 
      }
    },
    setExperience(relation) {
      this.$store.state.master_agency_experience_id = relation.id;
      this.chooseId = relation.id;
      this.agencyExperienceName = relation.text.toLowerCase().split(' ').join('_');
    },
    getResponseAgencyExperiences() {
      axios.get(`${this.outside.env}/api/e-recruitment/agency-experiences`).then(({ data }) => {
        this.relations = [];
        this.$store.state.master_agency_experience_id = data[0].id;
        this.chooseId = data[0].id;
        data.forEach((d) => { 
          this.relations.push({
            id: d.id,
            text: d.name_id
          })
        });
      });
    },
    submitERecuitment() {
      this.formData = {
        city_id: parseInt(this.$store.state.city_id),
        date_of_birth: this.$store.state.date_of_birth,
        full_name: this.$store.state.full_name,
        master_agency_experience_id: this.$store.state.master_agency_experience_id,
        phone_number: this.$store.state.mobile_number,
        referral_key: this.$store.state.referral_key,
        salutation: this.$store.state.salutation,
        utm_medium: this.$store.state.utm_medium,
        utm_campaign: this.$store.state.utm_campaign,
        utm_source: this.$store.state.utm_source
      }

      let gender = '';
      if(this.formData.salutation == 'bapak') {
        gender = 'male';
      } else {
        gender = 'female';
      }

      axios.post(`${this.outside.env}/api/e-recruitment/register`, this.formData).then((res) => {

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "click_button",
          label: 'complete_form',
          category: 'form',
          province: this.$store.state.province_name.toLowerCase().split(' ').join('_'),
          city: this.$store.state.city_name.toLowerCase().split(' ').join('_'),
          agency_experience_id: this.$store.state.master_agency_experience_id,
          agency_experience: this.agencyExperienceName.toLowerCase().split(' ').join('_'),
          gender: gender
        });
        this.$router.push({ name: 'ErecruitmentSuccess'});
      }).catch((error) => {
        this.$store.state.errorText = error.response.data.error;
        this.$router.push({ name: 'ErecruitmentError'});
      });
    }
  }
};
</script>