// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("trix")
require("@rails/actiontext")

import RailsVueUJS from '../rails_vue_ujs';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import store from "../vue_components/store";
import axios from 'axios';

var componentRequireContext = require.context("vue_components", true);
RailsVueUJS.initialComponentsContext(componentRequireContext);
RailsVueUJS.use(VueRouter, Vuex);
RailsVueUJS.initializeVuexStore(store);
RailsVueUJS.use(axios);

self.RailsVueUJS = RailsVueUJS;
